import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  Button,
  Typography,
} from '@pebl/ui';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';

export type DialogProps = {
  title?: string;
  description?: string;
  isOpen?: boolean;
  className?: string;

  /*
   * The primary action button text
   */
  confirmText?: string;
  /*
   * Callback for the primary action button
   */
  onConfirm?: () => void;
  /*
   * Optional secondary action button text
   */
  cancelText?: string;
  /*
   * Callback for the secondary action button
   */
  onCancel?: () => void;
};

export function Dialog({
  cancelText,
  children,
  className,
  confirmText,
  description,
  isOpen,
  onCancel,
  onConfirm,
  title,
}: PropsWithChildren<DialogProps>) {
  const showFooter = confirmText || cancelText;

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent
        className={clsx(
          className,
          `qng-dialog-container flex max-w-[calc(100%-2rem)] flex-col gap-2`,
        )}
      >
        {/* Hidden title and description for radix aria accessibility */}
        <AlertDialogTitle className="sr-only">
          {title ?? 'No title'}
        </AlertDialogTitle>
        <AlertDialogDescription className="sr-only">
          {description ?? 'No description'}
        </AlertDialogDescription>

        {title && (
          <Typography variant="h4" className="qng-dialog-title">
            {title}
          </Typography>
        )}

        {description && (
          <Typography variant="body2" className="qng-dialog-description">
            {description}
          </Typography>
        )}

        {/* Custom content */}
        {children && <div className="qng-dialog-content">{children}</div>}

        {showFooter && (
          <AlertDialogFooter
            className={`
              gap-2

              sm:space-x-0
            `}
          >
            {cancelText && (
              <Button variant="text" onClick={onCancel}>
                {cancelText}
              </Button>
            )}
            {confirmText && (
              <Button variant="default" onClick={onConfirm}>
                {confirmText}
              </Button>
            )}
          </AlertDialogFooter>
        )}
      </AlertDialogContent>
    </AlertDialog>
  );
}
