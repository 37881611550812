import Hotjar from '@hotjar/browser';
import TagManager from '@sooro-io/react-gtm-module';
import ReactGA from 'react-ga4';

import log from '@/utils/logging';

import { AnalyticsEngineInstance } from './types';
import { isTagManagerReady } from './utils/is-tag-manager-ready';
import { sanitiseFields } from './utils/sanitise-fields';

/**
 * The Google Tag Manager stuff doesn't seem to play too nicely with
 * the concept of a "long lived" SPA site, so the data layer is
 * persistent and "global".
 * The problem with this for us is if we set a datalayer variable
 * for a list viewed event for "listType": "attractions", then this would
 * be in the data layer for that event but ALSO for all subsequent events
 * too.
 *
 * This should no longer be an issue as I've updated bits so that:
 * - We keep specific data nested within an appropriate key, e.g. for
 *   an event there's the eventData object to contain any data relevant.
 * - On it's own the above isn't enough as GTM would merge existing data
 *   with new so we also need to explicitly include the `_clear: true` flag
 *   in the data to prevent the default deep merging behaviour.
 *
 * This now means if we are careful about how we structure our data
 * we can avoid the issue of data persisting between events (unless we
 * specifically want it to e.g. userData which we may want to persist across
 * all events while someone is logged in).
 */

export const analyticsFuncs: AnalyticsEngineInstance = {
  /**
   * AUTHENTICATION (LOGIN/LOGOUT)
   */
  analyticsLogin: (data = {}, opts = {}) => {
    const { dataLayer, method, userId } = data;
    const eventId = 'login';
    const actionId = 'login';

    if (ReactGA.isInitialized && !opts?.disableGoogleAnalytics) {
      ReactGA.event({ category: eventId, action: actionId, label: method });
      log.debug(
        `📊 GA: ${eventId} event, action ${actionId}, with method ${method}`,
      );
    }

    if (isTagManagerReady() && !opts?.disableGoogleTagManager) {
      TagManager.dataLayer({
        dataLayer: {
          _clear: true, // GTM Internal to prevent merging data - https://stackoverflow.com/a/70598804
          event: eventId,
          eventData: sanitiseFields({
            ...(dataLayer ?? {}),
            actionType: actionId,
          }),
          userData: sanitiseFields({
            id: userId,
            loginMethod: method,
          }),
        },
      });
      log.debug(
        `🏷️ GTM: ${eventId} event, action ${actionId}, with method ${method}`,
      );
    }

    if (Hotjar.isReady() && !opts?.disableHotjar) {
      Hotjar.event(eventId);
      log.debug(
        `🔥 HJ: ${eventId} event, action ${actionId}, with method ${method}`,
      );
    }
  },
  analyticsLogout: (data = {}, opts = {}) => {
    const eventId = 'logout';
    const actionId = 'logout';

    if (ReactGA.isInitialized && !opts?.disableGoogleAnalytics) {
      ReactGA.event({ category: eventId, action: actionId });
      log.debug(`📊 GA: ${eventId} event, action ${actionId}`);
    }

    if (isTagManagerReady() && !opts?.disableGoogleTagManager) {
      TagManager.dataLayer({
        dataLayer: {
          _clear: true, // GTM Internal to prevent merging data - https://stackoverflow.com/a/70598804
          event: eventId,
          eventData: sanitiseFields({
            ...data.dataLayer,
            actionType: actionId,
          }),
          userData: undefined, // unset
        },
      });
      log.debug(`🏷️ GTM: ${eventId} event, action ${actionId}`);
    }

    if (Hotjar.isReady() && !opts?.disableHotjar) {
      Hotjar.event(eventId);
      log.debug(`🔥 HJ: ${eventId} event, action ${actionId}`);
    }
  },

  /**
   * ACTIONS
   */
  actionExecuted: ({ actionType, dataLayer }, opts = {}) => {
    const eventId = 'action.execute';
    const actionId = actionType;

    if (ReactGA.isInitialized && !opts?.disableGoogleAnalytics) {
      ReactGA.event({
        category: eventId,
        action: actionId,
      });
      log.debug(`📊 GA: ${eventId} event, action ${actionId}`);
    }

    if (isTagManagerReady() && !opts?.disableGoogleTagManager) {
      TagManager.dataLayer({
        dataLayer: {
          _clear: true, // GTM Internal to prevent merging data - https://stackoverflow.com/a/70598804
          event: eventId,
          eventData: sanitiseFields({
            ...dataLayer,
            actionType: actionId,
          }),
        },
      });
      log.debug(`🏷️ GTM: ${eventId} event, action ${actionId}`);
    }

    if (Hotjar.isReady() && !opts?.disableHotjar) {
      Hotjar.event(`${eventId} - ${actionId}`);
      log.debug(`🔥 HJ: ${eventId} event, action ${actionId}`);
    }
  },
  actionViewed: ({ actionType, dataLayer }, opts = {}) => {
    const eventId = 'action.viewed';
    const actionId = actionType;

    if (ReactGA.isInitialized && !opts?.disableGoogleAnalytics) {
      ReactGA.event({
        category: eventId,
        action: actionId,
      });
      log.debug(`📊 GA: ${eventId} event, action ${actionId}`);
    }

    if (isTagManagerReady() && !opts?.disableGoogleTagManager) {
      TagManager.dataLayer({
        dataLayer: {
          _clear: true, // GTM Internal to prevent merging data - https://stackoverflow.com/a/70598804
          event: eventId,
          eventData: sanitiseFields({
            ...dataLayer,
            actionType: actionId,
          }),
        },
      });
      log.debug(`🏷️ GTM: ${eventId} event, action ${actionId}`);
    }

    if (Hotjar.isReady() && !opts?.disableHotjar) {
      Hotjar.event(`${eventId} - ${actionId}`);
      log.debug(`🔥 HJ: ${eventId} event, action ${actionId}`);
    }
  },
  actionCancelled: ({ actionType, dataLayer }, opts = {}) => {
    const eventId = 'action.cancelled';
    const actionId = actionType;

    if (ReactGA.isInitialized && !opts?.disableGoogleAnalytics) {
      ReactGA.event({
        category: eventId,
        action: actionId,
      });
      log.debug(`📊 GA: ${eventId} event, action ${actionId}`);
    }

    if (isTagManagerReady() && !opts?.disableGoogleTagManager) {
      TagManager.dataLayer({
        dataLayer: {
          _clear: true, // GTM Internal to prevent merging data - https://stackoverflow.com/a/70598804
          event: eventId,
          eventData: sanitiseFields({
            ...dataLayer,
            actionType: actionId,
          }),
        },
      });
      log.debug(`🏷️ GTM: ${eventId} event, action ${actionId}`);
    }

    if (Hotjar.isReady() && !opts?.disableHotjar) {
      Hotjar.event(`${eventId} - ${actionId}`);
      log.debug(`🔥 HJ: ${eventId} event, action ${actionId}`);
    }
  },

  /**
   * LISTS
   */
  listViewed: ({ dataLayer, listType }, opts = {}) => {
    const eventId = 'list.viewed';

    if (ReactGA.isInitialized && !opts?.disableGoogleAnalytics) {
      ReactGA.event({ category: eventId, action: listType });
      log.debug(`📊 GA: ${eventId} event, listType ${listType}`);
    }

    if (isTagManagerReady() && !opts?.disableGoogleTagManager) {
      TagManager.dataLayer({
        dataLayer: {
          _clear: true, // GTM Internal to prevent merging data - https://stackoverflow.com/a/70598804
          event: eventId,
          eventData: sanitiseFields({
            ...dataLayer,
            listType,
          }),
        },
      });
      log.debug(`🏷️ GTM: ${eventId} event, listType ${listType}`);
    }

    if (Hotjar.isReady() && !opts?.disableHotjar) {
      Hotjar.event(`${eventId} - ${listType}`);
      log.debug(`🔥 HJ: ${eventId} event, listType ${listType}`);
    }
  },

  listItemSelected: ({ dataLayer, itemSelected, listType }, opts = {}) => {
    const eventId = 'list.item_selected';

    if (ReactGA.isInitialized && !opts?.disableGoogleAnalytics) {
      ReactGA.event({
        category: eventId,
        action: listType,
        label: JSON.stringify(itemSelected),
      });
      log.debug(
        `📊 GA: ${eventId} event, listType ${listType}, itemSelected ${itemSelected}`,
      );
    }

    if (isTagManagerReady() && !opts?.disableGoogleTagManager) {
      TagManager.dataLayer({
        dataLayer: {
          _clear: true, // GTM Internal to prevent merging data - https://stackoverflow.com/a/70598804
          event: eventId,
          eventData: sanitiseFields({
            ...dataLayer,
            listType,
            itemSelected,
          }),
        },
      });
      log.debug(
        `🏷️ GTM: ${eventId} event, listType ${listType}, itemSelected ${itemSelected}`,
      );
    }

    if (Hotjar.isReady() && !opts?.disableHotjar) {
      Hotjar.event(`${eventId} - ${listType}`);
      log.debug(
        `🔥 HJ: ${eventId} event, listType ${listType}, itemSelected ${itemSelected}`,
      );
    }
  },

  /**
   * INTERNAL FUNCTION, Please avoid using this directly.
   * Use the proper analytics functions instead.
   * This will almost certainly be removed at some point.
   */
  setDataLayer(data, dataLayerName = undefined, clear) {
    TagManager.dataLayer({
      dataLayer: {
        _clear: clear,
        ...sanitiseFields(data),
      },
      dataLayerName,
    });
  },
};

/*
 * // https://stackoverflow.com/questions/43673440/resetting-data-layer-variables-in-single-page-applications-google-tag-manager
 * const resetDataLayer = () => {
 *   if (
 *     window.dataLayer !== undefined &&
 *     window.google_tag_manager !== undefined
 *   ) {
 *     window.dataLayer.length = 0;
 *     const gtmContainerReg = /GTM-/i;
 */

/*
 *     for (const gtmKey of Object.keys(window.google_tag_manager)) {
 *       if (
 *         gtmContainerReg.test(gtmKey) &&
 *         window.google_tag_manager[gtmKey].dataLayer &&
 *         window.google_tag_manager[gtmKey].dataLayer.reset
 *       ) {
 *         window.google_tag_manager[gtmKey].dataLayer.reset();
 *       }
 *     }
 *   }
 * };
 */
