import { isAxiosError } from 'axios';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import {
  ApiResourceAction,
  ApiResourceActionMessage,
  ApiSubmitActionErrorResponse,
} from '@/api/types';
import { Dialog } from '@/components/dialog';
import useAnalytics from '@/core/analytics/hooks';
import { addNotification } from '@/stores/qng-data-store';
import { TranslationComponentProps } from '@/types/translation';
import log from '@/utils/logging';
import { isBaseError } from '@/utils/typeguards';

import { useUnknownAction } from '../../hooks/use-action';
import { ActionFormCallbacks, ActionFormRef } from '../../types';
import { ActionFormBuilderRef } from '../action-form-builder';
import { ActionFormView } from './action-form-view';

type ActionFormDataProps = {
  /**
   * The action to render the form for.
   */
  action: ApiResourceAction;

  /**
   * An optional parameter that can be set to prevent any redirection
   * handling the API may include in the response from being handled
   * higher up the stack (up in the API level).
   * Generally we wouldn't want to prevent this, but there may be
   * cases where we need to.
   */
  preventRedirect?: boolean;

  /**
   * If `true`, the title of the action will not be shown in the form.
   * @default false - The title will be displayed.
   */
  hideTitle?: boolean;

  /**
   * Optionally provide class names to add to the ActionForm container.
   */
  className?: string;
} & TranslationComponentProps &
  ActionFormCallbacks;

export const ActionFormData = forwardRef<ActionFormRef, ActionFormDataProps>(
  function ActionFormData(
    {
      action,
      className,
      hideTitle,
      onActionCancelled,
      onActionChained,
      onActionError,
      onActionSuccess,
      preventRedirect,
      t,
    }: ActionFormDataProps,
    ref,
  ) {
    const [currentAction, setCurrentAction] = useState<
      ApiResourceAction | undefined
    >(action);

    const [actionResponseMessage, setActionResponseMessage] = useState<
      ApiResourceActionMessage | undefined
    >(undefined);

    const { actionCancelled, actionExecuted, actionViewed } = useAnalytics();

    // On mount only, fire the actionViewed event
    useEffect(() => {
      if (currentAction) {
        actionViewed?.({ actionType: currentAction.type });
      }
    }, [actionViewed, currentAction]);

    const handleActionCancelled = useCallback(
      (action?: ApiResourceAction) => {
        if (action) {
          // Invoke the analytics call to indicate the action was cancelled
          actionCancelled?.({ actionType: action.type });
        }

        // Run the callback
        onActionCancelled?.(action);
      },
      [actionCancelled, onActionCancelled],
    );

    // A ref to the inner form so we can set/clear errors and interact with the form if required
    const formBuilderRef = useRef<ActionFormBuilderRef>(null);

    useImperativeHandle<ActionFormRef, ActionFormRef>(
      ref,
      () => ({
        resetForm: () => formBuilderRef.current?.reset(),
      }),
      [],
    );

    const { executeAction, isPending } = useUnknownAction();

    const submitAction = useCallback(
      async (formData: unknown) => {
        if (!currentAction) {
          // TODO: Throw error?
          return;
        }

        await executeAction(
          {
            preventRedirect,
            action: currentAction,
            data: formData,
          },
          {
            onSuccess: (data) => {
              // log.debug('ActionForm: action success', data);

              // Invoke the analytics engine to track the action execution
              actionExecuted?.({
                actionType: currentAction.type,
                dataLayer: {
                  formData: formData,
                },
              });

              /*
               * If the response contains properties indicating we should show a message to
               * the user before we proceed (in the form of a dialog box) then we handle that here.
               */
              if (data.title || data.description) {
                setActionResponseMessage(data);
              }

              const chainedAction = data?.action;

              if (chainedAction) {
                /*
                 * log.debug(
                 *   'ActionForm: API Action response contained a chained action',
                 *   chainedAction,
                 * );
                 */

                onActionChained?.(currentAction, chainedAction);

                /*
                 * TODO: Prevent any possible redirection from the previous action that
                 *       is handled higher up in the API layer, though I would hope the API
                 *       is sensible enough to NOT include a redirect in a response that also
                 *       includes a chained action.
                 */
                setCurrentAction(chainedAction);

                formBuilderRef?.current?.reset();
              } else {
                /*
                 * If a success callback was provided, call it with the response data
                 * but only if there was no chained action.
                 */
                onActionSuccess?.(data);

                setCurrentAction(action);
              }
            },
            onError: (error) => {
              log.error('ActionForm: API call resulted in an error', error);

              let errorMessage = isBaseError(error)
                ? error.message
                : t('actions.unknown_error');

              if (isAxiosError<ApiSubmitActionErrorResponse>(error)) {
                const responseBody = error?.response?.data;
                const generalError = responseBody?.error;
                const propertyErrors = responseBody?.properties ?? {};

                // Update the form with any property errors returned by the API
                for (const [key, value] of Object.entries(propertyErrors)) {
                  formBuilderRef?.current?.setError(key, {
                    type: 'manual',
                    message: value,
                  });
                }

                /*
                 * Override the more generic Axios error (e.g. "Request failed with status code 400")
                 * with a more specific error provided by the API IF one exists.
                 */
                if (generalError) {
                  errorMessage = generalError;
                }

                // If a failure callback was provided, call it with the error response
                onActionError?.(responseBody);
              }

              addNotification({
                type: 'error',
                message: errorMessage,
              });
            },
          },
        );
      },
      [
        currentAction,
        executeAction,
        preventRedirect,
        actionExecuted,
        onActionChained,
        onActionSuccess,
        action,
        t,
        onActionError,
      ],
    );

    if (!currentAction) {
      return false;
    }

    return (
      <>
        <Dialog
          isOpen={!!actionResponseMessage}
          title={actionResponseMessage?.title}
          description={actionResponseMessage?.description}
          onConfirm={() => setActionResponseMessage(undefined)}
          onCancel={() => setActionResponseMessage(undefined)}
          confirmText={
            actionResponseMessage?.submit ??
            t('actions.actions_response_message.default_confirm_text')
          }
        />
        <ActionFormView
          ref={formBuilderRef}
          action={currentAction}
          preventRedirect={preventRedirect}
          onActionCancelled={handleActionCancelled}
          hideTitle={hideTitle}
          submitAction={submitAction}
          isPending={isPending}
          className={className}
          t={t}
        />
      </>
    );
  },
);
