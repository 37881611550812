import { useCallback, useState } from 'react';

import { CommonViewProps } from '@/types/core';
import { TranslationComponentProps } from '@/types/translation';

type UserDebugViewProps = {
  userUid?: string;
  deployVariantDebugData?: string;
} & TranslationComponentProps &
  CommonViewProps;

export function UserDebugView({
  deployVariantDebugData,
  userUid,
}: UserDebugViewProps) {
  const tapsRequired = 5;

  const [showUid, setShowUid] = useState<boolean>(false);
  const [taps, setTaps] = useState<number>(0);
  const handleShowUid = useCallback(() => {
    if (showUid) {
      setShowUid(false);
      setTaps(0);
      return;
    }

    if (taps === tapsRequired - 1) {
      // third tap
      setShowUid(true);
      setTaps(0);
    } else {
      setTaps((prev) => prev + 1);
    }
  }, [showUid, taps]);

  const canDecodeData = deployVariantDebugData?.includes(';');

  return (
    <div className="qng-user-debug">
      <div
        className="qng-user-debug-button min-h-8 w-full"
        onClick={handleShowUid}
      >
        &nbsp;
        {showUid && (
          <div className="qng-user-debug-data grid gap-2 text-sm italic opacity-50">
            <div className="w-[280px]">{userUid}</div>
            <div className="inline-block w-[280px] text-wrap break-words">
              {canDecodeData
                ? deployVariantDebugData
                    ?.split(';')
                    .map((d) => <div key={d}>{d}</div>)
                : deployVariantDebugData}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
