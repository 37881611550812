import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { ErrorFallback } from '@/components/error-fallback';

import { UserDebugData } from './user-debug-data';
import { UserDebugView } from './user-debug-view';

export function UserDebug() {
  const { t } = useTranslation();

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={(p) => <ErrorFallback {...p} t={t} />}
        >
          <Suspense fallback={<UserDebugView loading t={t} />}>
            <UserDebugData t={t} />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
