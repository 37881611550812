/*
 * Maybe not the best way but this will let us know if GTM is ready
 * or even in use so we can avoid making unnecessary calls in the above
 * functions.
 */
export const isTagManagerReady = () => {
  if (window.google_tag_manager === undefined) {
    // log.warn('GTM not ready');
    return false;
  }

  return true;
};
