import { useCookies } from 'react-cookie';

/**
 * This isn't used anywhere but is more just a placeholder so
 * in the future we know / have a way to access the user UID
 * cookie if we desire.
 */
export function useUserUid() {
  const [cookies] = useCookies(undefined, {
    doNotUpdate: true,
    doNotParse: true,
  });

  const userUid: string | undefined = cookies['qng-client-unique-id'];

  /*
   * // This is a raw way to access the cookie without the react-cookie library if required.
   * const uniqueUserId = useMemo(
   *   () =>
   *     document.cookie
   *       .split(';')
   *       .find((x) => x.startsWith('qng-client-unique-id='))
   *       ?.split('=')?.[1],
   *   [],
   * );
   */

  return {
    userUid,
  };
}
