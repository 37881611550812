import { sensitiveFields } from './sensitive-fields';

/**
 * We want to avoid accidentally sending sensitive data to our analytics
 * that we REALLY shouldn't
 * e.g. a password field in a login event.
 *
 * This function will strip out any sensitive fields from the data object
 * based on the sensitiveFields array in the sensitive_fields.ts file,
 * add any more field names that may be required there either as text OR as a regex.
 */
export function sanitiseFields(
  data: Record<string, unknown>,
  extraFields?: RegExp[],
): Record<string, unknown> {
  const sanitisedData = { ...data };

  const checkFields = sensitiveFields
    .concat(extraFields ?? [])
    .map((field) => (field instanceof RegExp ? field : new RegExp(field, 'i')));

  // Recurse down through ALL keys in the data object at all levels
  const recurse = (obj: Record<string, unknown>) => {
    for (const key of Object.keys(obj)) {
      if (checkFields.some((x) => x.test(key))) {
        // console.log('Deleting', key, obj);
        delete obj[key];
        // console.log(obj);
      } else if (typeof obj[key] === 'object') {
        recurse(obj[key] as Record<string, unknown>);
      }
    }
  };

  recurse(sanitisedData);

  return sanitisedData;
}
