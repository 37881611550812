/**
 * Keep any Typeguards we implement in here.
 */

import { ApiSubmitActionErrorResponse } from '@/api/types';

/**
 * Check if a value is a string.
 *
 * @param value - The value to check.
 * @returns boolean - Whether the value is a string or not.
 */
export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

/**
 * Check if a value is boolean.
 *
 * @param value - The value to check.
 * @returns boolean - Whether the value is a boolean or not.
 */
export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

/**
 * Check if a value is a number.
 *
 * @param value - The value to check.
 * @returns boolean - Whether the value is a number or not.
 */
export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

/**
 * Check if a value is an object.
 *
 * @param value - The value to check.
 * @returns boolean - Whether the value is an object or not.
 */
export function isObject(value: unknown): value is Record<string, unknown> {
  return (
    typeof value === 'object' &&
    value !== null &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value as any).length === undefined // Ensure it's not an array at runtime
  );
}

/**
 *
 * @param value
 * @param union
 * @returns boolean - Whether the value is within the union or not.
 */
export function isWithinUnion<T extends string>(
  value: string | unknown,
  union: T[],
): value is T {
  if (typeof value !== 'string') return false;
  return union.includes(value as T);
}

/**
 * Check if an error is an instance of the base Error class.
 *
 * @param error - The error to check.
 * @returns boolean - Whether the error is an instance of the base Error class or not.
 */
export function isBaseError(error: unknown): error is Error {
  return error instanceof Error;
}

/**
 * Check if an error is of the form ApiSubmitActionErrorResponse.
 *
 * @param error - The error to check.
 * @returns boolean - Whether the error is of the form ApiSubmitActionErrorResponse or not.
 */
export function isActionSubmitResponseError(
  error: unknown,
): error is ApiSubmitActionErrorResponse {
  return !!(error as ApiSubmitActionErrorResponse).error;
  // TODO: Should this also check for the existence of the properties object?
}
