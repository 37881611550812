import { useUserUid } from '@/hooks/use-user-uid';
import { useQngDataStore } from '@/stores/qng-data-store';
import { TranslationComponentProps } from '@/types/translation';

import { UserDebugView } from './user-debug-view';

type UserDebugDataProps = {} & TranslationComponentProps;

export function UserDebugData({ t }: UserDebugDataProps) {
  const { userUid } = useUserUid();
  const deployVariantDebugData = useQngDataStore(
    (state) => state.deployVariantDebugData,
  );

  return (
    <UserDebugView
      t={t}
      userUid={userUid ?? '71c297ae-9c3b-46cc-bf2b-6aa0e7d6156c'}
      deployVariantDebugData={
        deployVariantDebugData ??
        'variantName=default;site=variant-debugging;themebranch=master;theme=example12315124123124512412'
      }
    />
  );
}
