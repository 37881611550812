import { useContext } from 'react';

import { AnalyticsEngineContext } from './consts';
import { AnalyticsEngineInstance } from './types';

/**
 * A hook to use our analytics engine.
 * Use the functions available in the returned object to invoke
 * analytics events.
 */
export default function useAnalytics(): AnalyticsEngineInstance {
  const context = useContext(AnalyticsEngineContext);

  if (context === undefined) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  }

  return context;
}
