import { AxiosError, isAxiosError } from 'axios';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ApiResourceAction,
  ApiResourceActionMessage,
  ApiResourceActionResponse,
  ApiSubmitActionErrorResponse,
} from '@/api/types';
import { Dialog } from '@/components/dialog';
import useAnalytics from '@/core/analytics/hooks';
import { addNotification } from '@/stores/qng-data-store';
import log from '@/utils/logging';

import { isInstantAction } from '../../../../utils/is-instant-action';
import { useUnknownAction } from '../../hooks/use-action';
import { mapPreventRedirect } from '../../utils/map-prevent-redirect';
import {
  ActionListDialog,
  ActionListDialogProps,
} from './internal/action-list-dialog';
import {
  ActionListSheet,
  ActionListSheetProps,
} from './internal/action-list-sheet';
import { CommonActionListDisplayInternalProps } from './internal/types';

type ActionListDisplayMethod = 'dialog' | 'sheet';

export type ActionListProps = {
  /**
   * The method to use to display the action form of the selected action.
   * @default 'dialog'
   */
  displayMethod?: ActionListDisplayMethod;

  /**
   * Optionally provide class names to add to the ActionList container.
   */
  className?: string;

  /*
   * The Omit's below are to exclude internal properties from
   * the final ActionListProps type.
   */
} & Omit<ActionListDialogProps, keyof CommonActionListDisplayInternalProps> &
  Omit<ActionListSheetProps, keyof CommonActionListDisplayInternalProps>;

/**
 * A component to display a list of actions as buttons that can be clicked to
 * open a dialog or sheet with the form for that action.
 * @param actions The list of actions to display in the ActionList.
 * @param displayMethod The method to use to display the action form of the selected action. Default is `dialog`.
 * @param sheetSide If `displayMethod` is set to `sheet`, this prop can be used to set the side
 * from which the sheet should appear. Default is `bottom`.
 * @returns The ActionList component.
 */
export function ActionList({
  actionFormClassName,
  actions,
  className,
  closeCancelsAction = true,
  dialogClassName,
  displayMethod = 'dialog',
  fullScreen,
  fullScreenCloseButton,
  hideTitle = false,
  onActionCancelled,
  onActionChained,
  onActionError,
  onActionSuccess,
  preventRedirect,
}: ActionListProps) {
  const { t } = useTranslation();

  const validActions = actions?.filter((action) => {
    /*
     * If an action is malformed or has missing properties that
     * are required then we just can't handle them, so
     * we filter them out and don't show a button for them.
     */
    if (!action.type) {
      return false;
    }

    // NOTE: Add any more validation here

    return true;
  });

  /**
   * This is the action that is/was selected from the ActionList.
   * If this action causes subsequent chaining etc then this should
   * still stay the same and reference the initial parent action.
   * NOTE: We don't currently use this state but the intention is we keep track of
   *       the initiator/parent action as we may require it later.
   */
  const [, setListSelectedAction] = useState<ApiResourceAction | undefined>(
    undefined,
  );

  /**
   * This is the current action.
   * Generally this will match listSelectedAction (at-least initially).
   *
   * In the case of a "normal" action all the handling will be passed down and happen
   * within the ActionForm (ideal) and this will just match the listSelectedAction, however
   * if the list action clicked is an "instant action" (I.E. no properties, just a href)
   * then that gets handled up here and so if that action is a chained action then we need
   * to handle the follow up action.
   * If the follow up isn't another instant action then we can pass it down to the ActionForm
   * for proper handling, however if it IS another instant action then we need to handle that
   * up here again.
   * TODO: Implement an instant action response having a chained action that contains another instant action
   */
  const [currentAction, setCurrentAction] = useState<
    ApiResourceAction | undefined
  >(undefined);

  const [actionResponseMessage, setActionResponseMessage] = useState<
    ApiResourceActionMessage | undefined
  >(undefined);

  /**
   * If the list item clicked is an "instant action" (I.E. no properties, just a href)
   * then this will be set to that action so we can track it separately.
   */
  const [activeInstantAction, setActiveInstantAction] = useState<
    ApiResourceAction | undefined
  >(undefined);

  const { actionCancelled, actionExecuted } = useAnalytics();

  const { executeAction, isPending } = useUnknownAction();

  const handleActionSuccess = useCallback(
    (response?: ApiResourceActionResponse, action?: ApiResourceAction) => {
      /*
       * If the response contains properties indicating we should show a message to
       * the user before we proceed (in the form of a dialog box) then we handle that here.
       */
      if (response?.title || response?.description) {
        setActionResponseMessage(response);
      }

      onActionSuccess?.(response, action);
    },
    [onActionSuccess],
  );

  const handleActionCancelled = useCallback(
    (action?: ApiResourceAction) => {
      if (action) {
        // Invoke the analytics call to indicate the action was cancelled
        actionCancelled?.({ actionType: action.type });
      }

      // Run the callback
      onActionCancelled?.(action);
    },
    [actionCancelled, onActionCancelled],
  );

  const setAction = useCallback(
    (action: ApiResourceAction | undefined) => {
      /*
       * The UI decision was to NOT disable other buttons in the ActionList
       * when an instant execution action is executing, but instead just
       * ignore clicks on the buttons ... :|
       */
      if (isPending || !!activeInstantAction) {
        log.debug('Action is already pending, ignoring click');
        return;
      }

      if (action) {
        if (isInstantAction(action) && !action.disabled) {
          // If the action is an instant action, we should submit it immediately
          log.debug('Submitting instant action', action);

          setActiveInstantAction(action);

          return (
            executeAction({
              action,
              data: {},
              preventRedirect: mapPreventRedirect(preventRedirect, action),
            })
              .then((response) => {
                log.debug(
                  'Instant Action submitted successfully, response = ',
                  response,
                );

                if (action) {
                  // Invoke the analytics engine to track the action execution
                  actionExecuted?.({
                    actionType: action?.type,
                    dataLayer: {},
                  });
                }

                /*
                 * If the API response includes a follow up (chained action) then
                 * we should handle that. For now we just assume it isn't another
                 * instant action (why would the API do that anyway) and just pass
                 * the follow up action down to the ActionForm for "proper" processing.
                 */
                if (response.action) {
                  onActionChained?.(action, response.action);

                  setCurrentAction(response.action);

                  /*
                   * Leave the listSelectedAction as the original action so we know the
                   * reference to the parent action that was clicked.
                   */
                } else {
                  /*
                   * We only fire off the success callback from here if there is no chaining,
                   * otherwise we always wait for a chain to finish before invoking the
                   * success callback.
                   */
                  handleActionSuccess(response, action);

                  setListSelectedAction(undefined);
                  setCurrentAction(undefined);
                }
              })
              // TODO: Reassess this axios specific handling spilling into here
              .catch((error: AxiosError | Error) => {
                log.error('Error submitting action', error);

                let errorNotificationMessage = t('actions.unknown_error');

                if (isAxiosError<ApiSubmitActionErrorResponse>(error)) {
                  const responseBody = error.response?.data;
                  log.error('Axios error', responseBody);

                  onActionError?.(responseBody, action);

                  if (responseBody?.error) {
                    errorNotificationMessage = responseBody.error;
                  }
                }

                addNotification({
                  type: 'error',
                  message: errorNotificationMessage,
                });
              })
              .finally(() => {
                setActiveInstantAction(undefined);
              })
          );
        }
      }

      setListSelectedAction(action);
      setCurrentAction(action);
    },
    [
      actionExecuted,
      activeInstantAction,
      executeAction,
      handleActionSuccess,
      isPending,
      onActionChained,
      onActionError,
      preventRedirect,
      t,
    ],
  );

  return (
    <>
      <Dialog
        isOpen={!!actionResponseMessage}
        title={actionResponseMessage?.title}
        description={actionResponseMessage?.description}
        onConfirm={() => setActionResponseMessage(undefined)}
        onCancel={() => setActionResponseMessage(undefined)}
        confirmText={
          actionResponseMessage?.submit ??
          t('actions.actions_response_message.default_confirm_text')
        }
      />
      <div className={clsx('qng-action-list flex flex-col gap-2', className)}>
        {displayMethod === 'dialog' ? (
          <ActionListDialog
            actions={validActions}
            selectedAction={currentAction}
            setSelectedAction={setAction}
            hideTitle={hideTitle}
            isPending={isPending}
            closeCancelsAction={closeCancelsAction}
            onActionError={onActionError}
            onActionSuccess={handleActionSuccess}
            onActionCancelled={handleActionCancelled}
            onActionChained={onActionChained}
            actionFormClassName={actionFormClassName}
            dialogClassName={dialogClassName}
            activeInstantAction={activeInstantAction}
            fullScreen={fullScreen}
            fullScreenCloseButton={fullScreenCloseButton}
            preventRedirect={preventRedirect}
            t={t}
          />
        ) : (
          <ActionListSheet
            actions={validActions}
            selectedAction={currentAction}
            setSelectedAction={setAction}
            hideTitle={hideTitle}
            isPending={isPending}
            closeCancelsAction={closeCancelsAction}
            onActionError={onActionError}
            onActionSuccess={handleActionSuccess}
            onActionCancelled={handleActionCancelled}
            onActionChained={onActionChained}
            actionFormClassName={actionFormClassName}
            sheetClassName={dialogClassName}
            activeInstantAction={activeInstantAction}
            preventRedirect={preventRedirect}
            t={t}
          />
        )}
      </div>
    </>
  );
}

export default ActionList;
