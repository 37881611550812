import Hotjar from '@hotjar/browser';
import TagManager from '@sooro-io/react-gtm-module';
import { Logger } from 'loglevel';
import { useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga4';
import { InitOptions } from 'react-ga4/types/ga4';

import { QNG_COOKIE_NAME } from '@/utils/constants';
import log from '@/utils/logging';

import { AnalyticsEngineContext } from './consts';
import { analyticsFuncs } from './engine-functions';
import { AnalyticsEngineInstance } from './types';
import { isTagManagerReady } from './utils/is-tag-manager-ready';

type AnalyticsGoogleAnalyticsProps = {
  /**
   * Whether to enable Google Analytics or not (default: true).
   * Requires `googleMeasurementIds` to be set to actually function.
   * The main reason for this is to allow enabling/disabling Google Analytics without having to
   * completely remove the ID from the deployment.
   */
  googleAnalyticsEnabled?: boolean;

  /**
   * Google Analytics Measurement IDs.
   *
   * This can be a single ID or multiple IDs separated by commas
   * e.g.
   * 12345,abcdef
   */
  googleAnalyticsMeasurementIds?: string;
};

type AnalyticsHotjarProps = {
  /**
   * The Hotjar ID this deployment should use.
   * If not set then won't setup HotJar.
   *
   * You can also explicitly disable hotjar by setting the `enableHotjar` prop to `false`.
   *
   * This is retrieved from / set in the Versions repository host.json file on the
   * `config.hotjarId` property of a host or variant.
   */
  hotjarId?: number; // Integer

  /**
   * Whether to enable Hotjar or not (default: true).
   * Requires `hotjarId` to be set to actually function.
   * The main reason for this is to allow enabling/disabling hotjar without having to
   * completely remove the ID from the deployment.
   */
  hotjarEnabled?: boolean;

  /**
   * Target version of hotjar.
   * We probably have no reason to ever change this
   * Default is 6.
   */
  hotjarVersion?: number;

  /**
   * Whether to enable debug mode for Hotjar.
   * This is useful for development environments.
   */
  hotjarDebug?: boolean;
};

type AnalyticsGoogleTagManagerProps = {
  /**
   * Whether to enable Google Tag Manager or not (default: true).
   * Requires `googleTagManagerId` to be set to actually function.
   */
  googleTagManagerEnabled?: boolean;

  /**
   * Google Tag Manager ID.
   */
  googleTagManagerId?: string;

  /**
   * Whether to enable Google Tag Manager test mode or not (default: false).
   * Primarily this is only for when we are running tests etc.
   */
  googleTagManagerTestMode?: boolean;
};

type AnalyticsProviderProps = {
  /**
   * The name of the cookie that determines if analytics are allowed.
   *
   * This is set elsewhere on the site (e.g. cookie policy page).
   */
  analyticsAllowedCookieName?: string;

  /**
   * When we run unit tests, we set this to true to just disable some "noisy" logging
   * and to ensure we don't actively use HotJar or Google Tag Manager.
   */
  testMode?: boolean;
} & AnalyticsGoogleAnalyticsProps &
  AnalyticsHotjarProps &
  AnalyticsGoogleTagManagerProps;

/**
 * Our analytics provider for the site.
 * Currently this just sets up Hotjar, but in the future
 * we could add Google Analytics etc setup here too.
 *
 * Future Improvements: This could become a context and provide lower components
 *                      a common interface to interact with Hotjar etc.
 */
export function AnalyticsProvider({
  analyticsAllowedCookieName = QNG_COOKIE_NAME,
  children,
  googleAnalyticsEnabled = true,
  googleAnalyticsMeasurementIds,
  googleTagManagerEnabled = true,
  googleTagManagerId,
  googleTagManagerTestMode = false,
  hotjarDebug = false,
  hotjarEnabled = true,
  hotjarId,
  hotjarVersion = 6,

  testMode = false,
}: React.PropsWithChildren<AnalyticsProviderProps>) {
  const [cookies] = useCookies([analyticsAllowedCookieName], {
    doNotParse: true,
  });

  const analyticsAllowed = cookies[analyticsAllowedCookieName] === 'true';

  const internalLog = useMemo<Logger | undefined>(() => {
    if (!testMode) {
      return log;
    }
    return undefined;
  }, [testMode]);

  useEffect(() => {
    internalLog?.debug('🔎 Analytics Setup...');

    if (!analyticsAllowed) {
      internalLog?.info(
        'Analytics are disabled (cookie disallows), skipping setup.',
        analyticsAllowedCookieName,
        cookies[analyticsAllowedCookieName],
      );
      return;
    }

    if (!!googleAnalyticsMeasurementIds && googleAnalyticsEnabled === true) {
      internalLog?.debug(
        '📊 Setting up Google Analytics',
        googleAnalyticsMeasurementIds,
      );

      // Check if we've been given multiple Google measurement IDs
      const measureIds = googleAnalyticsMeasurementIds
        .split(',')
        .filter((x) => !!x && x.length > 0);

      /*
       * NOTE: Back in V1 this HAD to be outside the React context (I.E. top of the file)
       *       so if we experience bugs, consider trying that.
       */
      const initPayload = measureIds.map<InitOptions>((id) => ({
        trackingId: id,
        /*
         * gaOptions
         * gtagOptions
         */
      }));
      ReactGA.initialize(initPayload, {
        testMode: googleTagManagerTestMode,
      });
    }

    if (
      !!googleTagManagerId &&
      googleTagManagerEnabled === true &&
      !isTagManagerReady()
    ) {
      log.info('🏷️ Initializing Google Tag Manager', googleTagManagerId);

      TagManager.initialize({
        gtmId: googleTagManagerId,
      });
    }

    if (hotjarId !== undefined && isNaN(hotjarId)) {
      internalLog?.error('Invalid Hotjar ID provided:', hotjarId);
    }

    if (!Hotjar.isReady()) {
      if (hotjarId && hotjarEnabled === true) {
        internalLog?.debug('🔥 Setting up Hotjar', hotjarId);

        Hotjar.init(hotjarId, hotjarVersion, {
          debug: hotjarDebug,
        });
      }
    }

    // There is no way to cleanup Hotjar unfortunately.
    return () => {
      // ReactGA.reset()
    };
  }, [
    analyticsAllowed,
    analyticsAllowedCookieName,
    cookies,
    googleAnalyticsEnabled,
    googleAnalyticsMeasurementIds,
    googleTagManagerEnabled,
    googleTagManagerId,
    googleTagManagerTestMode,
    hotjarDebug,
    hotjarEnabled,
    hotjarId,
    hotjarVersion,
    internalLog,
  ]);

  const instance = useMemo<AnalyticsEngineInstance | undefined>(() => {
    return analyticsAllowed ? analyticsFuncs : {};
  }, [analyticsAllowed]);

  return (
    <AnalyticsEngineContext.Provider value={instance}>
      {children}
    </AnalyticsEngineContext.Provider>
  );
}
