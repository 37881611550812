import { useCallback, useEffect } from 'react';

import {
  getSiteSettings,
  getThemeSettings,
} from '@/api/functions/core/settings';
import useConfigThemePath from '@/hooks/use-config-theme-path';
import {
  setAppSettings,
  setDeployVariantDebugData,
} from '@/stores/qng-data-store';
import { AppSettings } from '@/types/settings';
import log from '@/utils/logging';

type AppSettingsProviderProps = {
  /**
   * The path to the settings.json that the sites
   * cloudfront distribution will specially serve.
   */
  siteSettingsPath?: string;

  /**
   * The filename to the settings.json within the active
   * theme (if set) that we will try to load and merge
   * with the site settings.
   */
  themeSettingsFile?: string;

  /**
   * Whether to make the requests with a version query
   * string suffix to prevent caching issues.
   * e.g. /settings.json?v=123123
   * Generally we shouldn't need this and rely on the
   * S3 + Cloudfront caching configuration.
   */
  enableVersionSuffix?: boolean;

  /**
   * The property on the returned object that contains the
   * settings. This is because rather than keep the settings
   * on the root of the object, I decided to set them on a
   * property called `settings` to allow for future use of the
   * file for other purposes.
   */
  settingsPropertyName?: string;
};

/**
 * This component is responsible for loading our application
 * settings from the Cloudfront distribution and the theme
 * repository (if set).
 *
 * These settings are set in the hosts.json file in the versions repo,
 * but are NOT stored in KVS due to size constraints. Instead these
 * are processed into per host/variant settings (merging the host and variant
 * settings together) then this is served per host as a settings.json at the
 * `/settings.json` Cloudfront path.
 *
 * This component also tries to load the settings.json from the theme
 * repository if a theme is set. This is to allow for theme specific
 * settings to be set.
 *
 * The main purpose for these application settings is to allow enabling
 * or disabling various functionality in the site per host/variant/theme, quickly
 * and easily all from the existing hosts.json file we already have.
 * These settings are crucial to us being able to do A/B testing with the
 * existing variant system I created in the hosts.json file, as you can now
 * specify that a variant should have a certain feature enabled or disabled.
 *
 * **USAGE**: To actually make use of app settings you first need to add the setting
 * to the `hosts.json` file in the versions repo (either at the host level to apply
 * to all variants OR to a specific variant). Alternatively you can add settings to
 * the `settings.json` file in the theme repo to apply to all hosts using that theme.
 *
 * Next in this code base you can use the normal Zustand access pattern to get setting
 * out or the generic access hook `useSelectQngAppSetting` in the `store/selectors.ts`
 * If you have a "set" of settings that you always need together then consider making
 * a selector that pulls just those from the store and using that instead (see
 * the useSelectQngAuth example in the `store/selectors.ts` file) and remember to use
 * the useShallow hook as needed to prevent unnecessary re-renders
 * - https://zustand.docs.pmnd.rs/guides/prevent-rerenders-with-use-shallow
 *
 * NOTE: The above usage is still WIP as I finalise this.
 */
export function AppSettingsProvider({
  enableVersionSuffix = false,
  siteSettingsPath = '/settings.json',
  themeSettingsFile = 'settings.json',
}: AppSettingsProviderProps) {
  /**
   * Try to load the settings.json from the Cloudfront
   * distribution, then also try and load the settings.json
   * that may be present in the current theme (if set).
   *
   * Merge the 2 settings.json files together, with the
   * settings.json from the theme taking precedence.
   *
   * Then update the Zustand store with the final settings.
   * I think I've settled on using Zustand for storing these
   * rather than adding another full site wrapping context.
   * A Context would be more flexible, testable and unique
   * to our use cases but Zustand is less likely to cause
   * re-render issues for the time being (we can always
   * switch later if needed).
   */
  const theme = useConfigThemePath({});

  const loadSettings = useCallback(
    async ({ abortController }: { abortController: AbortController }) => {
      const settingsTargetUri = `${siteSettingsPath}${enableVersionSuffix ? `?v=${Math.floor(Math.random() * 999999)}` : ''}`;

      log.debug(
        '⚙️ AppSettingsProvider: loading site settings',
        settingsTargetUri,
      );

      /**
       * We intentionally don't use query client in here as this is
       * handled higher in the hierarchy than the QueryClientProvider
       * purposefully. This is because we want to load these settings
       * high up so they can be used to affect as much of the rest of the
       * app as possible. That does mean there are some minor down sides
       * by not using react query but this should be fine.
       */

      const { multiVersionHeaderData, siteSettings } = await getSiteSettings({
        signal: abortController.signal,
      })
        .then(([responseData, multiVersionHeaderData]) => {
          return {
            siteSettings: responseData?.settings ?? {},
            multiVersionHeaderData,
          };
        })
        .catch((error) => {
          log.error(
            `⚙️ AppSettingsProvider: Error loading site settings "${settingsTargetUri}": `,
            error,
          );
          return { siteSettings: {}, multiVersionHeaderData: undefined };
        });

      let themeSettings = {};
      if (theme) {
        const themeTargetUri = `/themes/${theme}${themeSettingsFile}`;

        log.debug(
          `⚙️ AppSettingsProvider: loading theme settings for theme: '${theme}' from uri: ${themeTargetUri}`,
        );

        themeSettings = await getThemeSettings({
          signal: abortController.signal,
          themePath: themeTargetUri,
        })
          .then((responseData) => responseData?.settings ?? {})
          .catch((error) => {
            log.error(
              `⚙️ AppSettingsProvider: Error loading theme settings "${themeTargetUri}": `,
              error,
            );
            return {};
          });
      }

      const combinedSettings = {
        ...siteSettings,
        ...themeSettings,
      } as AppSettings;

      log.debug(
        '⚙️ AppSettingsProvider: loaded final settings',
        combinedSettings,
      );

      return { combinedSettings, multiVersionHeaderData };
    },
    [enableVersionSuffix, siteSettingsPath, theme, themeSettingsFile],
  );

  // Load the settings on mount and if the theme ever changes
  useEffect(() => {
    const abortController = new AbortController();

    loadSettings({ abortController }).then(
      ({ combinedSettings, multiVersionHeaderData }) => {
        // Update the Zustand store with the new settings
        setAppSettings(combinedSettings);

        if (multiVersionHeaderData) {
          log.debug(
            '⚙️ AppSettingsProvider: multi version header data',
            multiVersionHeaderData,
          );
        }

        setDeployVariantDebugData(multiVersionHeaderData);
      },
    );

    return () => {
      abortController.abort();
    };
  }, [loadSettings]);

  return false;
}
