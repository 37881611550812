import {
  ApiGetBasketDataResponse,
  CancellableApiOptions,
  CommonQueryProps,
} from '@/api/types';
import { ResourceQueryKeyFn } from '@/types/invalidation';

import { axios } from '../../axios';
import { getCommonHeaders } from '../../utils/headers';

const ENDPOINT = `/basket`;

export const resourceQueryKey: ResourceQueryKeyFn = () => ['basket'];

export async function getBasket(
  options: CancellableApiOptions & CommonQueryProps = {},
): Promise<ApiGetBasketDataResponse> {
  const { data } = await axios.get(ENDPOINT, {
    signal: options?.signal,
    headers: getCommonHeaders(options),
  });

  return data;
}
