import {
  MutationScope,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { BeforeRedirectCallback, submitAction } from '@/api/functions/actions';
import {
  ApiResourceAction,
  ApiResourceActionResponse,
  ApiSubmitActionErrorResponse,
  CommonQueryProps,
} from '@/api/types';
import { mapApiActionMethodToHttpMethod } from '@/api/utils/map-action-method';
import log from '@/utils/logging';
import { mapApiResourceUrlToResource } from '@/utils/map-api-resource-url-to-resource';

type useSubmitActionProps = {
  /**
   * The URL to submit the action to.
   */
  url: string;

  /**
   * The HTTP method to use for the request.
   */
  method: 'POST' | 'GET';

  /**
   * An optional callback that gets called prior to any redirection which
   * is handled by the API layer.
   */
  beforeRedirect?: BeforeRedirectCallback;

  /**
   * Passed up to the API layer to prevent redirection if the response
   * indicated it should redirect (via a 301 + Location header OR in
   * the response body).
   *
   * Normally we would want to allow the API action to redirect us as
   * it sees fit, however sometimes this may need to be overridden.
   */
  preventRedirect?: boolean;

  data?: unknown;

  /**
   * Only used for special cases where we need to run mutations in a stable serial
   * manner.
   * See - https://tanstack.com/query/latest/docs/framework/react/guides/mutations#mutation-scopes
   */
  scope?: MutationScope | undefined;

  /**
   * There are 2 places to handle mutation callbacks, in useMutation when setting up the mutation
   * and in the mutate call when actually using it. Sometimes it's required to set it in useMutation
   * and this callback allows for that in specific use cases, generally though prefer the mutate() callback.
   */
  onSuccess?: (data: ApiResourceActionResponse) => void;
  onError?: (error: Error | ApiSubmitActionErrorResponse) => void;
  onSettled?: (
    data: ApiResourceActionResponse | undefined,
    error: Error | ApiSubmitActionErrorResponse | null,
  ) => void;
};

export function useSubmitActionMutation(
  props: CommonQueryProps<useSubmitActionProps>,
) {
  const queryClient = useQueryClient();

  return useMutation<
    ApiResourceActionResponse,
    Error | ApiSubmitActionErrorResponse,
    unknown,
    unknown
  >({
    onSuccess: props.onSuccess,
    onError: props.onError,
    onSettled: props.onSettled,
    mutationFn: async (data: unknown) =>
      submitAction({
        url: props.url,
        submitData: data,
        beforeRedirect: props.beforeRedirect,
        preventRedirect: props.preventRedirect,
        method: props.method,
        options: {
          ...props,
        },
      }).then((actionResponse) => {
        // !WARNING: This is directly copied from below from the 'new' version of the hook, consolidate these ASAP!
        if (
          actionResponse?.invalidated_resources &&
          actionResponse.invalidated_resources.length > 0
        ) {
          log.debug(
            'Action Response contains invalidated resources',
            actionResponse.invalidated_resources,
          );

          // Invalidate the resources that were returned in the response
          const keysToInvalidate = actionResponse.invalidated_resources
            .map((resourceUri) => mapApiResourceUrlToResource(resourceUri))
            .filter((x) => !!x);

          for (const key of keysToInvalidate) {
            log.debug('Invalidating resource key', key);

            queryClient.invalidateQueries({ queryKey: key });
          }
        }

        return actionResponse;
      }),
    scope: props.scope,
  });
}

export function useSubmitActionMutationNew(props: CommonQueryProps) {
  const queryClient = useQueryClient();

  return useMutation<
    ApiResourceActionResponse,
    Error | ApiSubmitActionErrorResponse,
    {
      beforeRedirect?: BeforeRedirectCallback;
      data: unknown;
      preventRedirect?: boolean;
      action: ApiResourceAction;
    },
    unknown
  >({
    mutationFn: async ({
      action,
      beforeRedirect,
      data,
      preventRedirect,
    }: {
      beforeRedirect?: BeforeRedirectCallback;
      data: unknown;
      preventRedirect?: boolean;
      action: ApiResourceAction;
    }) => {
      const url = action?.href ?? '';
      const method = mapApiActionMethodToHttpMethod(action?.method ?? 'get');

      return submitAction({
        url,
        submitData: data,
        beforeRedirect,
        preventRedirect,
        method,
        options: {
          ...props,
        },
      }).then((actionResponse) => {
        // If the action response contains invalidated resources, invalidate them
        if (
          actionResponse?.invalidated_resources &&
          actionResponse.invalidated_resources.length > 0
        ) {
          log.debug(
            'Action Response contains invalidated resources',
            actionResponse.invalidated_resources,
          );

          // Invalidate the resources that were returned in the response
          const keysToInvalidate = actionResponse.invalidated_resources
            .map((resourceUri) => mapApiResourceUrlToResource(resourceUri))
            .filter((x) => !!x);

          for (const key of keysToInvalidate) {
            log.debug('Invalidating resource key', key);

            queryClient.invalidateQueries({ queryKey: key });
          }
        }

        return actionResponse;
      });
    },
  });
}
