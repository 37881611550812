import {
  CreditCardIcon,
  HelpIcon,
  InfoIcon,
  LanguageIcon,
  MobilePhoneIcon,
  PersonIcon,
  StarIcon,
  TicketIcon,
} from '@pebl/ui';

import { Divider } from '@/components/divider';
import RoutePathConstants from '@/entrypoints/guest-website/routing/route-path-constants';
import { CommonViewProps } from '@/types/core';
import { TranslationComponentProps } from '@/types/translation';

import { UserDebug } from '../debug';
import { ShopItem } from '../shop-item';
import { SideBarItem } from '../side-bar-item';
import { SignInOutView } from '../sign-in-out-item';

type SideBarViewProps = {
  isUserSignedIn?: boolean;
} & TranslationComponentProps &
  CommonViewProps;

export function SideBarView({
  isUserSignedIn,
  loading,
  refetching,
  t,
}: SideBarViewProps) {
  return (
    <>
      <div className="qng-side-bar flex h-full flex-col gap-2 overflow-auto">
        <div className="qng-side-bar-main">
          <SideBarItem
            className="qng-side-bar-attractions"
            loading={loading}
            name={t('sidebar.attractions')}
            icon={<StarIcon className="size-6" />}
            route={RoutePathConstants.ATTRACTIONS.link()}
          />

          <ShopItem loading={loading} />

          {isUserSignedIn && (
            <SideBarItem
              className="qng-side-bar-account"
              loading={loading}
              name={t('sidebar.account')}
              icon={<PersonIcon className="size-6" />}
              route={RoutePathConstants.ACCOUNT.link()}
            />
          )}
        </div>

        <Divider />

        <div className="qng-side-bar-secondary">
          <SignInOutView
            isSignedIn={isUserSignedIn}
            loading={loading}
            refetching={refetching}
            t={t}
          />

          <SideBarItem
            className="qng-side-bar-change-language"
            loading={loading}
            name={t('sidebar.change_language')}
            icon={<LanguageIcon className="size-6" />}
            route={RoutePathConstants.CHANGE_LANGUAGE.link()}
          />

          <SideBarItem
            className="qng-side-bar-claim-tickets"
            loading={loading}
            name={t('sidebar.claim_tickets')}
            icon={<TicketIcon outlined className="size-6" />}
            route={RoutePathConstants.FLOW_ENTITLEMENT_CLAIMING.link('ticket')}
          />

          <SideBarItem
            className="qng-side-bar-claim-membership"
            loading={loading}
            name={t('sidebar.claim_membership')}
            icon={<CreditCardIcon className="size-6" />}
            route={RoutePathConstants.FLOW_ENTITLEMENT_CLAIMING.link(
              'membership',
            )}
          />
        </div>

        <Divider />

        <div className="qng-side-bar-other">
          {t('sidebar.get_the_app') && (
            <SideBarItem
              className="qng-side-bar-get-the-app"
              loading={loading}
              name={t('sidebar.get_the_app')}
              icon={<MobilePhoneIcon className="size-6" />}
              route={t('sidebar.get_the_app_link')}
              targetBlank={true}
            />
          )}

          {t('sidebar.how_to_use') && (
            <SideBarItem
              className="qng-side-bar-how-to-use"
              loading={loading}
              name={t('sidebar.how_to_use')}
              icon={<HelpIcon className="size-6" />}
              route={t('sidebar.how_to_use_link')}
              targetBlank={true}
            />
          )}

          <SideBarItem
            className="qng-side-bar-cookie-policy"
            loading={loading}
            name={t('sidebar.cookie_policy')}
            icon={<InfoIcon className="size-6" />}
            route={RoutePathConstants.COOKIE_POLICY.link()}
          />
        </div>
      </div>
      <div className="qng-side-bar-bottom">
        <UserDebug />
      </div>
    </>
  );
}
