import { CancellableApiOptions, CommonQueryProps } from '@/api/types';
import { ResourceQueryKeyFn } from '@/types/invalidation';

import { axios } from '../../axios';
import { getCommonHeaders } from '../../utils/headers';
import { ApiGetPaymentResponse } from './types';

const ENDPOINT = () => `/payment`;

export const resourceQueryKey: ResourceQueryKeyFn = () => ['payment'];

export async function getPayment(
  options: CancellableApiOptions & CommonQueryProps = {},
): Promise<ApiGetPaymentResponse> {
  const { data } = await axios.get(ENDPOINT(), {
    signal: options?.signal,
    headers: getCommonHeaders(options),
  });

  return data;
}
