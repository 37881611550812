import React, { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { AnimatedRoute } from '../animated-route';
import RoutePathConstants from '../route-path-constants';

const ResourceActionPage = React.lazy(
  () => import('@/features/actions/components/resource-action'),
);

export function ResourceActionRoute() {
  const { actionId, resourceId } = useParams();

  const animationKey = useMemo(
    () => `resource-${resourceId}-action-${actionId}`,
    [actionId, resourceId],
  );

  // If either of the supplied parameters aren't valid we can't continue
  if (
    !actionId ||
    actionId.trim().length === 0 ||
    !resourceId ||
    resourceId.trim().length === 0
  ) {
    return <Navigate to={RoutePathConstants.HOME.link()} />;
  }

  return (
    <AnimatedRoute animationKey={animationKey}>
      <div className="page-container">
        <ResourceActionPage actionId={actionId} resourceId={resourceId} />
      </div>
    </AnimatedRoute>
  );
}
